<template>
  <div class="list-info">
    <top-bar :title="'宣传录用'" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="搜索标题" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="8">
          <p @click="dateBarShow=!dateBarShow" :style="{color:dateBarShow?'#387FF5':'#666666' }">发布日期
            <img :src="require(`@/assets/img/${dateBarShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="timeBarShow=!timeBarShow" :style="{color:timeBarShow?'#387FF5':'#666666' }">登记日期
            <img :src="require(`@/assets/img/${timeBarShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="typeShow = !typeShow" :style="{color:typeShow?'#387FF5':'#666666' }">{{typeStr || '宣传类型'}}
            <img :src="require(`@/assets/img/${typeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow"><span>{{selectDate.startShowPost == ''?'最早':selectDate.startShowPost}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow"><span>{{selectDate.endShowPost== ''?'至今':selectDate.endShowPost}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="timeBarShow">
      <van-row>
        <van-col span="8" @click="startCreateTimeShow = !startCreateTimeShow"><span>{{selectDate.startCreateTime == ''?'开始时间':selectDate.startCreateTime}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="endCreateTimeShow = !endCreateTimeShow"><span>{{selectDate.endCreateTime== ''?'结束时间':selectDate.endCreateTime}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <van-popup v-model="typeShow" position="bottom"  >
      <van-picker title="标题" value-key="label" show-toolbar :columns="typeList" @confirm="typeConfim" cancel-button-text="重置" @cancel="typeCancel" />
    </van-popup>
    <van-popup v-model="beginDateShow" position="bottom">
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false"  type="date"
                           :formatter="formatDate" :min-date="minDate" :max-date="maxDate"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom">
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="date" :formatter="formatDate"
                           :min-date="minDate" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>
    <van-popup v-model="startCreateTimeShow" position="bottom">
      <van-datetime-picker @confirm="startTimeConfim" @cancel="startCreateTimeShow = false"  type="date"
                           :formatter="formatDate" :min-date="minDate" :max-date="maxDate"/>
    </van-popup>
    <van-popup v-model="endCreateTimeShow" position="bottom">
      <van-datetime-picker @confirm="endTimeConfim" @cancel="endCreateTimeShow = false" type="date" :formatter="formatDate"
                           :min-date="minDate" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>

    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <!-- <van-pull-refresh v-model="pullLoading" @refresh="onPullDownRefresh"> -->
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
            <div class="title">
              <div class="title-left">
                {{item.title}}
              </div>
              <div class="title-right">
                {{item.typeStr}}
              </div>
            </div>
            <div class="content">
              <van-image :src="item.screenshot?item.screenshot[0].url:require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="bottom-text">发布日期: {{item.postDate}}</div>
                <div class="bottom-text">登记日期: {{item.createTime.split(' ')[0]}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
    <!-- </van-pull-refresh> -->
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import { mapMutations } from 'vuex'
import {newDate} from "../../utils/utils";
export default {
  components :{
    topBar
  },
  data() {
    return {
      pullLoading: false,
      gridName: '所属网格',
      userName: '所属社工',
      loading: false,
      pullLoading: false,
      finished: false,
      endDateShow: false,
      beginDateShow: false,
      startCreateTimeShow: false,
      endCreateTimeShow: false,
      dateBarShow: false,
      timeBarShow: false,
      typeShow: false,
      typeStr: '',
      page: 0,
      limit: 10,
      searchValue: '',
      selectDate: {
        endShowPost: '',
        startShowPost: '',
        startCreateTime: '',
        endCreateTime: '',
        type: ''
      },
      typeList: [
        {label:'qwe',id: 1},
        {label:'qwe',id: 1},
        {label:'qwe',id: 1},
        {label:'qwe',id: 1}
      ],
      totalCount: 0,
      dataList: [],
      minDate: new Date(2017, 0, 1),
      maxDate: new Date(),
    };
  },
  methods: {
    ...mapMutations,
    getInfo (id) {
      this.$router.push({path: '/propaganda-add', query: {id: id}})
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/propaganda/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          searchValue: this.searchValue,
          startShowPost: this.selectDate.startShowPost,
          endShowPost: this.selectDate.endShowPost,
          startCreateTime: this.selectDate.startCreateTime,
          endCreateTime: this.selectDate.endCreateTime,
          type: this.selectDate.type
        })
      }).then(({data})=> {
        this.pullLoading =false
        if (data.code == 0) {
          this.pullLoading = false
          this.totalCount = data.page.totalCount
          if (this.dataList.length >= data.page.totalCount) {
            this.finished = true
          }
          // let dataList = data.page.list
          // dataList.map(item => {
          //   if (item.inspectionTime) {
          //     item.inspectionTime = item.inspectionTime.split(' ')[0]
          //   }
          // })
          this.dataList = this.dataList.concat(data.page.list)
          console.log(this.dataList.length)

          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    getTypeOptions () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'propagandaType',
          orgId: this.$orgId,
          showDisabled: false
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.typeList = data.dicts
        }
      },err=> {this.$toast.error({message:'宣传类型获取失败'})})
    },
    formatDate (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    /**
     * 页面相关事件处理函数--用户下拉动作
     */
    onPullDownRefresh (e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    onSearch () {
      this.page = 0
      this.dataList = []
      this.finished=false
      this.getDataList()
    },
    beginDateConfim (value) {
      let d = new Date(this.selectDate.endShowPost)
      if (this.selectDate.endShowPost != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.startShowPost = formatterDate(value)
      this.beginDateShow = false
    },
    endDateConfim (value) {
      let d = new Date(this.selectDate.startShowPost)
      if (this.selectDate.startShowPost != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endShowPost = formatterDate(value)
      this.endDateShow = false
    },
    startTimeConfim (value) {
      let d = new Date(this.selectDate.endCreateTime)
      if (this.selectDate.endCreateTime != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.startCreateTime = formatterDate(value)
      this.startCreateTimeShow = false
    },
    endTimeConfim (value) {
      let d = new Date(this.selectDate.startCreateTime)
      if (this.selectDate.startCreateTime != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endCreateTime = formatterDate(value)
      this.endCreateTimeShow = false
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.finished=false
      this.getDataList()
    },
    typeConfim (value) {
      this.page = 0
      this.typeStr = value.label
      this.selectDate.type = value.value
      this.dataList = []
      this.finished=false
      this.getDataList()
      this.typeShow = false
    },
    typeCancel() {
      this.page = 0
      this.dataList = []
      this.selectDate.type = ''
      this.typeShow= false
      this.finished=false
      this.getDataList()
    },
    changeDate () {
      this.dataList = []
      this.page = 0
      this.finished=false
      this.getDataList()
    },
    dateClose () {
      this.selectDate = {
        endShowPost: '',
        startShowPost: '',
        startCreateTime: '',
        endCreateTime: ''
      }
      this.finished=false
      this.getDataList()
    },
    goAdd () {
      this.$router.push('/propaganda-add')
    }
  },
  created () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDataList()
    this.getTypeOptions()
  }
}
</script>
<style scoped>
.bottom-text:first-child {
  margin-top: 0 !important;
}
</style>
